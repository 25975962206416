import styled from 'styled-components';
import { ImgWithPlaceholder } from '@shopback/ui';

const CardImg = styled(ImgWithPlaceholder)`
  width: 100%;
  object-fit: contain;
  object-position: center;
`;

export default CardImg;
