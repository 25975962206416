import React from 'react';
import { useToaster, Notification } from 'rsuite';
import { Button } from '@shopback/ui';
import CountSelector from 'components/CountSelector';
import { useProjectStore, useRootStore } from 'store/globalCtx';
import { ProductItem } from 'models/product';
import { ProductPageStore } from 'pages/ProductPage/store';
import { observer } from 'mobx-react';

type Props = {
  product: ProductItem;
  store: ProductPageStore;
};

const CartActions: React.FC<Props> = ({ product, store }: Props) => {
  const project = useProjectStore().project;
  const cartStore = useRootStore().cartStore;
  const cartItem = cartStore.findProduct(product.id);
  const inCart = !!cartItem;

  const toaster = useToaster();

  const checkRequiredAddons = () => {
    if (product.hasRequiredAddons && !store.isRequiredAddonsSelected) {
      const message = (
        <Notification type="warning" header="Ошибка">
          Выберите обязательные опции
        </Notification>
      );

      toaster.push(message);
      return false;
    }

    return true;
  };

  const onAddToCart = () => {
    if (!checkRequiredAddons()) {
      return;
    }

    cartStore.addToCart(product, store.selectedAddons);
  };

  const onCartInc = () => {
    if (!checkRequiredAddons()) {
      return;
    }

    cartStore.incCartCount(product, store.selectedAddons);
  };

  if (!inCart) {
    return (
      <Button
        fullWidth
        key="shop"
        onClick={onAddToCart}
        size="lg"
        appearance="primary"
      >
        {project?.productCallToActionTitle || 'Добавить в корзину'}
      </Button>
    );
  } else {
    return (
        <CountSelector
          incDisabled={cartItem?.incDisabled}
          value={cartStore.getProductCount(product.id)}
          onInc={onCartInc}
          onDec={() => cartStore.decCartCount(product, store.selectedAddons)}
        />
    );
  }
};

export default observer(CartActions);
