import { action, computed, makeObservable, observable } from 'mobx';
import { LoadingStageModel } from 'models/loadingStage';
import { ValueModel } from 'models/value';

export class ListModel<T, C extends string | number | symbol = string> {
  private _keys: C[];

  private _entities: Record<C, T>;

  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly hasMore: ValueModel<boolean> = new ValueModel<boolean>(true);

  constructor(
    { keys, entities }: { keys: C[]; entities: Record<C, T> } = {
      keys: [],
      entities: {} as Record<C, T>
    }
  ) {
    makeObservable<
      ListModel<T, C>,
      | '_keys'
      | '_entities'
    >(this, {
      _keys: observable,
      _entities: observable,

      reset: action,
      removeEntity: action,
      addEntity: action,
      addEntities: action,

      keys: computed,
      entities: computed,
      length: computed,
      items: computed,
    });

    this._keys = keys;
    this._entities = entities;
  }

  get keys(): C[] {
    return this._keys;
  }

  get entities(): Record<C, T> {
    return this._entities;
  }

  get items(): T[] {
    const arr: T[] = [];
    this._keys.forEach((id: C) => {
      const item = this._entities[id];

      if (item) {
        arr.push(item);
      }
    });

    return arr;
  }

  get length(): number {
    return this.items.length;
  }

  addEntity = ({
    entity,
    key,
    start = false
  }: {
    entity: T;
    key: C;
    start?: boolean;
  }): void => {
    this._entities[key] = entity;
    if (start) {
      this._keys.unshift(key);
    } else {
      this._keys.push(key);
    }
  };

  addEntities = ({
    entities,
    keys,
    initial,
    start
  }: {
    entities: Record<C, T>;
    keys: C[];
    initial: boolean;
    start?: boolean;
  }): void => {
    if (initial) {
      this._entities = entities;
      this._keys = keys;
      return;
    }

    this._entities = {
      ...this._entities,
      ...entities
    };
    if (start) {
      this._keys.unshift(...keys);
    } else {
      this._keys.push(...keys);
    }
  };

  reset = (): void => {
    this._keys = [];
    this._entities = {} as Record<C, T>;
  };

  removeEntity = (keyParam: C): void => {
    this._keys = this._keys.filter(key => key !== keyParam);
    delete this._entities[keyParam];
  };

  getEntity = (keyParam: C): T | null => {
    return this._entities[keyParam] || null;
  };
}
