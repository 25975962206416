export const ROUTES = {
  main: {
    index: '/',
    pattern: '*',
  },
  cart: '/cart',

  products: {
    index: '/products',
    indexWithCategory: '/products/:categoryId',
    make: (categoryId?: number) => `/products/${categoryId || ''}`
  },

  categories: {
    index: '/category',
    pattern: '/category/:categoryId',
    make: (id: number | string) => `${ROUTES.categories.index}/${id}`,
  },

  product: {
    index: '/product',
    pattern: '/product/:id',
    make: (id: number | string) => `${ROUTES.product.index}/${id}`,
  },
};
