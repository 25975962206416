import React from 'react';
import { IconButton, Panel, Stack } from 'rsuite';
import { Typography } from 'antd';
import { AddonFragment, AddonOptionFragment } from 'generated/graphql';
import { ProductPageStore } from 'pages/ProductPage/store';
import { observer } from 'mobx-react';
import CheckIcon from '@rsuite/icons/Plus';
import CloseIcon from '@rsuite/icons/Minus';
import { useProjectStore } from 'store/globalCtx';
import { getLocalePrice } from '@shopback/ui';

type Props = {
  item: AddonOptionFragment;
  addon: AddonFragment;
  store: ProductPageStore;
};

const AddonOption: React.FC<Props> = ({ item, addon, store }: Props) => {
  const isSelected = store.checkOption(addon, item);
  const projectStore = useProjectStore();

  return (
    <Panel
      // shaded
      bordered
      header={
        <Stack justifyContent="space-between">
          <div>
            <Typography.Text>{item.title}</Typography.Text>
            <div>
              <Typography.Text type="secondary">
                {item.extraCost
                  ? `+ ${getLocalePrice({ price: item.extraCost, currency: projectStore.currency })}`
                  : ''}
              </Typography.Text>
            </div>
          </div>
          <IconButton
            icon={isSelected ? <CloseIcon /> : <CheckIcon />}
            appearance={isSelected ? 'primary' : 'default'}
            onClick={() => store.toggleOption(addon, item)}
          />
        </Stack>
      }
    />
  );
};

export default observer(AddonOption);
