import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

export const useTGBackButton = () => {
  const navigate = useNavigate();
  // @ts-ignore
  const backBtn = Telegram.WebApp.BackButton;

  useEffect(() => {
    backBtn.onClick(() => {
      navigate(-1);
    });

    backBtn.show();

    return () => {
      backBtn.hide();
    };
  }, []);
};

export const TgBackBtn = () => {
  useTGBackButton();

  return null;
};
