import React, { useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { GlobalCtx, rootStore, useRootStore } from './store/globalCtx';
import Cart from 'pages/Cart';
import { ROUTES } from './config/routes';
import { useScrollTop } from './utils/useScrollTop';
import { Skeleton } from 'antd';
import ProductPage from 'pages/ProductPage';
import { observer } from 'mobx-react';
import { ApolloProvider } from '@apollo/client';
import { CustomProvider } from 'rsuite';
import MainPage from 'pages/MainPage';
import { useLocation } from 'react-router';
import { PageTemplate } from '@shopback/ui';

const App = () => {
  const rootStore = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  useScrollTop();

  React.useEffect(() => {
    rootStore
      .init()
      .then(
        (res) =>
          !res.isError && window.openData.openCartPage && navigate(ROUTES.cart)
      );
  }, []);

  return (
    <CustomProvider>
      <ApolloProvider client={rootStore.apolloClient}>
        {rootStore.initStage.isLoading && (
          <PageTemplate>
            <Skeleton active avatar paragraph={{ rows: 24 }} />
          </PageTemplate>
        )}
        {!rootStore.initStage.isLoading && (
          <GlobalCtx.Provider value={rootStore}>
            <Routes location={location}>
              <Route path={ROUTES.main.pattern} element={<MainPage />} />
              <Route path={ROUTES.cart} element={<Cart />} />
              <Route path={ROUTES.product.pattern} element={<ProductPage />} />
            </Routes>
          </GlobalCtx.Provider>
        )}
      </ApolloProvider>
    </CustomProvider>
  );
};

export default observer(App);
