import React from 'react';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import {ProductSmall, Product, PriceContainer} from '@shopback/ui';
import { useProjectStore, useRootStore } from 'store/globalCtx';
import {
  AddonOptionFragment,
  ProjectProjectViewModeChoices,
} from 'generated/graphql';
import { ROUTES } from 'config/routes';
import { ProductItem as ProductItemModel } from 'models/product';
import { SelectedAddonsMap } from 'models/product/types';
import ProductCartActions from 'pages/ProductList/ProductItem/ProductCartActions';
import { getLocalePrice } from '@shopback/ui';

type Props = {
  product: ProductItemModel;
  addons?: SelectedAddonsMap;
  size?: ProjectProjectViewModeChoices;
  inCartView?: boolean;
};

const ProductItem: React.FC<Props> = ({
  product,
  addons,
  size = ProjectProjectViewModeChoices.Large,
  inCartView = false,
  ...props
}: Props) => {
  const projectStore = useProjectStore();
  const navigate = useNavigate();

  const isSmall = size === ProjectProjectViewModeChoices.Small;
  const isMedium = size === ProjectProjectViewModeChoices.Medium;

  const maxCount = product.maxCount ? `${product.maxCount} шт.` : undefined;
  const priceInRubles = product.isFree
    ? maxCount
    : `${product.localePrice} ` +
      (maxCount ? `(${maxCount})` : '');

  const actions = (
    <ProductCartActions
      product={product}
      isSmall={isSmall}
      inCartView={inCartView}
      addons={addons}
    />
  );

  const handleClick = () => navigate(ROUTES.product.make(product.id));

  if (isSmall) {
    return <ProductSmall
      actions={actions}
      avatar={product.avatar}
      onClick={handleClick}
      name={product.name}
      description={<>
        <PriceContainer price={priceInRubles} />
        {inCartView && <>
          {product.size && <div>Размер: {product.size}</div>}
          {product.color && <div>Цвет: {product.color}</div>}
          {addons && Object.values(addons)
            .reduce(
              (acc, addon) => [
                ...acc,
                ...Object.values(addon.addonOptions),
              ],
              [] as AddonOptionFragment[]
            )
            .map((addonOption) => (
              <div key={addonOption.id}>
                {addonOption.title} (+ {getLocalePrice({ price: addonOption.extraCost, currency: projectStore.currency })})
              </div>
            ))}
        </>
        }
      </>
    }
    />
  }

  return <Product
    name={product.name}
    description={product.description || undefined}
    isMedium={isMedium}
    price={priceInRubles}
    onClick={handleClick}
    actions={actions}
    attachments={product.attachments.slice(0, 3).map((img) => ({
      src: img.smallImage.url || img.file,
      height: img.smallImage.height || undefined,
      width: img.smallImage.width || undefined
    }))}
  />
};

export default observer(ProductItem);
