import React from 'react';
import { ProductItem } from 'models/product';
import {
  AddonFragment,
  ProjectProjectViewModeChoices,
} from 'generated/graphql';
import {
  Button,
  ButtonToolbar,
  Drawer,
  IconButton,
  Panel,
  Stack,
} from 'rsuite';
import { observer } from 'mobx-react';
import { ProductPageStore } from 'pages/ProductPage/store';
import { List, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import PlusIcon from '@rsuite/icons/Plus';
import { Space } from 'components/ui';
import AddonOption from 'pages/ProductPage/Addons/AddonOption';
import CheckIcon from '@rsuite/icons/Check';

type Props = {
  product: ProductItem;
  addon?: AddonFragment | null;
  open: boolean;
  close: () => void;
  store: ProductPageStore;
};

const AddonModal: React.FC<Props> = ({
  product,
  addon,
  open,
  close,
  store,
}: Props) => {
  if (!addon) {
    return null;
  }

  return (
    <Drawer placement="bottom" open={open} onClose={close}>
      <Drawer.Header>
        <Drawer.Title>{addon.title}</Drawer.Title>
        <IconButton icon={<CheckIcon />} onClick={close} appearance="primary" />
      </Drawer.Header>
      <Drawer.Body>
        {addon.addonOptions.map((item) => (
          <Space key={item.id}>
            <AddonOption item={item} addon={addon} store={store} />
          </Space>
        ))}
      </Drawer.Body>
    </Drawer>
  );
};

export default observer(AddonModal);
