import React from 'react';
import { Placeholder } from 'rsuite';

type Props = {};

const ListLoader: React.FC<Props> = ({}: Props) => {
  return (
    <>
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
      <Placeholder.Paragraph style={{ marginTop: 30 }} graph="circle" />
    </>
  );
};

export default ListLoader;
