import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  toJS,
} from 'mobx';
import {
  AddonOptionFragment,
  CreateOrderDocument,
  CreateOrderMutationResponse,
  GetProductListDocument,
  GetProductListQuery,
  ProjectProjectCartModeChoices,
} from 'generated/graphql';
import { IRootStore } from 'types/rootStore';
import { CartItem } from 'models/cart';
import { BaseResponse } from 'types/meta';
import { LocalStorageKey } from 'types/localStorage';
import { CartItemsInLS, CartItemToEvent } from 'types/cart';
import { ICartStore } from 'types/cartStore';
import { LoadingStageModel } from 'models/loadingStage';
import { ProductItem } from 'models/product';
import { SelectedAddonsMap } from 'models/product/types';
import { compareAddons } from 'models/product/utils';

export class CartStore implements ICartStore {
  items: Array<CartItem> = [];
  readonly creatingOrder: LoadingStageModel = new LoadingStageModel();
  readonly rootStore: IRootStore;

  private handleItemsLength: IReactionDisposer;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      items: observable,
      orderSum: computed,
      isEmpty: computed,
      addToCart: action,
      init: action,
    });

    this.handleItemsLength = reaction(() => this.items.length, this.saveToLS);
  }

  async init() {
    if (!window.openData.resetCart) {
      this.restore();
    }
  }

  destroy() {
    this.handleItemsLength();
  }

  get orderSum(): number {
    return this.items.reduce((acc, cartItem) => acc + cartItem.sum, 0);
  }

  get isEmpty(): boolean {
    return !this.items.length;
  }

  findProduct(productId: string) {
    return this.items.find((i) => i.id === productId);
  }

  getProductCount(productId: string) {
    return this.items
      .filter((p) => p.id === productId)
      .reduce((acc, next) => acc + next.count, 0);
  }

  getProductIndex(productId: string, addonsMap: SelectedAddonsMap = {}) {
    return this.items.findIndex(
      (i) => i.id === productId && compareAddons(i.selectedAddons, addonsMap)
    );
  }

  findItem(productId: string, addonsMap: SelectedAddonsMap = {}) {
    return this.items.find(
      (item) =>
        item.id === productId && compareAddons(item.selectedAddons, addonsMap)
    );
  }

  addToCart = (
    product: ProductItem,
    selectedAddons: SelectedAddonsMap = {}
  ) => {
    const project = this.rootStore.projectStore.project;

    if (!project) {
      return;
    }

    const cartItem = CartItem.fromProduct(
      {
        ...product,
        price: product.price,
        selectedAddons: toJS(selectedAddons),
        count: product.maxCount,
        countInCart: 1,
      },
      this
    );

    this.items.push(cartItem);

    if (project.cartMode === ProjectProjectCartModeChoices.Single) {
      this.buy();
    }
  };

  incCartCount = (product: ProductItem, addons: SelectedAddonsMap = {}) => {
    let cartItem = this.findItem(product.id, addons);

    if (!cartItem && Object.keys(addons).length > 0) {
      return this.addToCart(product, addons);
    }

    if (!cartItem) {
      cartItem = this.findProduct(product.id);
    }

    if (!cartItem) {
      return;
    }

    cartItem.inc();
  };

  decCartCount = (product: ProductItem, addons: SelectedAddonsMap = {}) => {
    let cartItem = this.findItem(product.id, addons);

    if (!cartItem) {
      cartItem = this.findProduct(product.id);
    }

    if (!cartItem) {
      return;
    }

    if (cartItem.count === 1) {
      const index = this.getProductIndex(product.id, addons);
      this.items.splice(index, 1);
      return;
    }

    cartItem.dec();
  };

  isProductInCart(id: string): boolean {
    return !!this.findProduct(id);
  }

  saveToLS = () => {
    this.rootStore.localStorageHandler.setItem(
      LocalStorageKey.cart,
      JSON.stringify(this.items.map((cartItem) => cartItem.toLSFormat()))
    );
  };

  /**
   * Синхронизация корзины
   * @returns {Promise<BaseResponse>}
   */
  async restore(): Promise<BaseResponse> {
    const lsData = this.rootStore.localStorageHandler.getItem(
      LocalStorageKey.cart
    );

    if (!lsData) {
      return {
        isError: false,
      };
    }

    const parsed: CartItemsInLS = JSON.parse(lsData);

    if (!Array.isArray(parsed)) {
      return {
        isError: true,
      };
    }

    const { data } =
      await this.rootStore.apolloClient.query<GetProductListQuery>({
        query: GetProductListDocument,
        variables: {
          limit: 1000,
          filters: {
            ids: parsed.map((item) => Number(item.id)),
          },
        },
      });

    if (!data?.productList?.products) {
      return {
        isError: true,
      };
    }

    this.items = parsed
      .map((lsItem) => {
        const product = data.productList?.products.find(
          (p) => p.id === lsItem.id
        );

        if (!product) {
          return null;
        }

        const selectedAddons = Object.keys(lsItem.addons || {}).reduce(
          (acc, addonId) => {
            const addon = product.addons?.find((addon) => addon.id === addonId);
            if (addon) {
              const parsedOptions = lsItem.addons?.[addonId];
              const addonOptions = addon.addonOptions.filter(
                (option) => (parsedOptions || []).indexOf(option.id) > -1
              );

              acc[addonId] = {
                addon,
                addonOptions: addonOptions.reduce(
                  (acc, next) => ({
                    ...acc,
                    [next.id]: next,
                  }),
                  {} as Record<string, AddonOptionFragment>
                ),
              };
            }

            return acc;
          },
          {} as SelectedAddonsMap
        );

        return CartItem.fromProduct(
          {
            ...product,
            selectedAddons,
            countInCart: lsItem.count,
          },
          this
        );
      })
      .filter(Boolean) as CartItem[];

    this.saveToLS();

    return {
      isError: false,
    };
  }

  private async createOrder(): Promise<BaseResponse<{ token: string }>> {
    if (this.creatingOrder.isLoading || !this.rootStore.projectStore.project) {
      return {
        isError: true,
      };
    }

    this.creatingOrder.loading();

    const { data } = await this.rootStore.apolloClient.mutate<{
      createOrder: CreateOrderMutationResponse;
    }>({
      mutation: CreateOrderDocument,
      variables: {
        project: this.rootStore.projectStore.project.id,
        products: JSON.stringify(
          this.items.map((item) => item.toCreatingCartFormat())
        ),
      },
    });

    if (!data?.createOrder?.data?.token) {
      this.creatingOrder.error();

      return {
        isError: true,
      };
    }

    this.creatingOrder.success();

    return {
      isError: false,
      data: {
        token: data.createOrder.data.token,
      },
    };
  }

  buy = async (): Promise<BaseResponse> => {
    const project = this.rootStore.projectStore.project;

    if (!project) {
      return {
        isError: true,
      };
    }

    const response = await this.createOrder();

    if (response.isError) {
      // TODO показывать уведомление об ошибке и саму ошибку data.errors...
      return {
        isError: true,
      };
    }

    const data = {
      ...this.toText(),
      ...this.toJson(),
      openData: window.openData,
      token: response.data.token,
    };

    if (project.cartMode === ProjectProjectCartModeChoices.Single) {
      this.items = [];
    }

    window.Telegram.WebApp.sendData(JSON.stringify(data));

    return {
      isError: false,
    };
  };

  toText() {
    return {
      total: this.orderSum,
      text: this.items
        .map((item, i) => `${i + 1}. ${item.toText()}`)
        .join('\n\n'),
    };
  }

  toJson(): {
    total: number;
    products: CartItemToEvent[];
  } {
    return {
      total: this.orderSum,
      products: this.items.map((item) => item.toEvent()),
    };
  }
}
