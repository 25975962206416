import React from 'react';
import { observer } from 'mobx-react';
import { Button, Stack } from 'rsuite';
import { SmallCategoriesListContainer, SmallCategory } from '@shopback/ui';
import { useProjectStore } from 'store/globalCtx';
import { SIZE, Space } from 'components/ui';

type Props = {
  onChange(v: string | null): void;
  selected?: string | null;
  category?: string;
};

const Categories: React.FC<Props> = ({ onChange, category, selected }) => {
  const projectStore = useProjectStore();
  const { project } = projectStore;

  if (!project) {
    return null;
  }

  const categories = category
    ? projectStore.getCategoryInProject(category)?.childCategories || []
    : project.categories;

  if (categories.length === 0) {
    return null;
  }

  return (
    <Space>
      <SmallCategoriesListContainer>
        <SmallCategory
          id="all"
          name="Все"
          selected={selected === category || selected === null}
          onClick={() => onChange(category || null)}
        />
        {categories.map((cat, i) => (
          <SmallCategory
            key={cat.id}
            id={cat.id}
            onClick={onChange}
            selected={selected === cat.id}
            name={cat.name}
          />
        ))}
      </SmallCategoriesListContainer>
    </Space>
  );
};

export default observer(Categories);
