export enum LocalStorageKey {
  project = 'project',
  token = 'token',
  cart = 'cart'
}

export interface ILocalStorageHandler {
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  remove(key: string): void;
}