import {
  AddonFragment,
  AddonType,
  ImageAttachmentFragment,
  ProductItemFragment,
  ProductModificationFragment,
  ProductOptionDict,
  ProductOptionsSet,
} from 'generated/graphql';
import { IProjectStore } from 'types/projectStore';
import { getLocalePrice } from '@shopback/ui';

export class ProductItem implements Omit<ProductItemFragment, 'count'> {
  readonly id: string;
  readonly name: string;
  readonly description?: string | null;
  private _price: any;
  readonly attachments: ImageAttachmentFragment[];
  readonly maxCount: number | null;
  readonly productOptions: ProductOptionDict;
  readonly productOptionsSet: ProductOptionsSet;
  readonly productModifications?: ProductModificationFragment[] | null;
  readonly productAddons?: AddonFragment[] | null;

  readonly projectStore: IProjectStore;

  constructor({
    id,
    name,
    description,
    price,
    attachments,
    maxCount,
    productOptions,
    productModifications,
    productOptionsSet,
    addons,
  }: Omit<ProductItemFragment, 'count'> & { maxCount: number | null }, projectStore: IProjectStore) {
    this.id = id;
    this.name = name;
    this.description = description;
    this._price = price;
    this.attachments = attachments;
    this.maxCount = maxCount;
    this.productOptions = productOptions;
    this.productOptionsSet = productOptionsSet;
    this.productModifications = productModifications;
    this.productAddons = addons;

    this.projectStore = projectStore;
  }

  get hasRequiredAddons(): boolean {
    return Boolean(this.productAddons?.some((addon) => addon.isRequired));
  }

  get price(): number {
    return this._price;
  }

  get localePrice(): string {
    return getLocalePrice({ price: this.price, currency: this.projectStore.currency});
  }

  get color(): string | null | undefined {
    return this.productOptions?.color?.key;
  }

  get size(): string | null | undefined {
    return this.productOptions?.size?.key;
  }

  get avatar(): string | null {
    if (!this.attachments.length) {
      return null;
    }

    return this.attachments[0].file;
  }

  get isFree(): boolean {
    return this.price <= 0;
  }

  get colorOptions() {
    return this.productOptionsSet?.color || [];
  }

  get sizeOptions() {
    return this.productOptionsSet?.size || [];
  }

  static fromJson(data: ProductItemFragment, projectStore: IProjectStore): ProductItem {
    return new ProductItem({
      ...data,
      price: Number(data.price) || 0,
      maxCount: typeof data.count === 'number' ? data.count : null,
    }, projectStore);
  }
}
