import React, {useState} from 'react';
import { ProductsListContainer, SearchInput, Space, ProductsPageTemplate } from '@shopback/ui';
import InfiniteScroll from 'react-infinite-scroll-component';

import {useProjectStore, useRootStore} from 'store/globalCtx';
import { useNavigate, useParams } from 'react-router-dom';
import {ProjectProjectViewModeChoices} from 'generated/graphql';
import ProductItem from 'pages/ProductList/ProductItem';
import {ROUTES} from 'config/routes';
import {observer} from 'mobx-react';
import Categories from 'pages/ProductList/Categories';
import {ProductListStore} from 'pages/ProductList/store';
import { Loader } from 'rsuite';
import ListLoader from 'components/ListLoader';

import { LoaderWrapper } from './styles';
import { TgBackBtn } from 'components/TGBackButton';

const ProductList: React.FC = () => {
  const rootStore = useRootStore();
  const cartStore = rootStore.cartStore;
  const navigate = useNavigate();
  const project = useProjectStore().project;
  const { categoryId } = useParams<{ categoryId: string }>();
  const [store] = useState(() => new ProductListStore(rootStore, categoryId));

  React.useEffect(() => {
    store.load();
  }, []);

  const isMedium = project?.viewMode === ProjectProjectViewModeChoices.Medium;

  if (!project) {
    return null;
  }

  return (
    <>
      <TgBackBtn />
      <ProductsPageTemplate title={project.mainPageTitle || undefined} button={!cartStore.isEmpty ? {
        onClick: () => navigate(ROUTES.cart),
        title: project.cartButtonTitle || undefined
      } : undefined}
      categories={project.showCategory && (
          <Categories
            category={categoryId}
            onChange={store.category.change}
            selected={store.category.value}
          />
      )}
      search={project.showSearch && <SearchInput value={store.search.value} onChange={store.search.change} />}
      list={<InfiniteScroll
        scrollableTarget="PRODUCTS_LIST_ID"
        dataLength={store.products.length}
        next={() => store.load()}
        hasMore={store.products.hasMore.value}
        loader={
          <LoaderWrapper>
            <Loader content="Загрузка" />
          </LoaderWrapper>
        }
      >
        {store.products.loadingStage.isLoading && <ListLoader />}
        <ProductsListContainer columnsCount={project.viewMode === ProjectProjectViewModeChoices.Medium ? 2 : 1} products={store.products.items.map((item) => (
          <ProductItem key={item.id} product={item} size={project.viewMode} />
        ))} />
      </InfiniteScroll>}
      />
    </>
  );
};

export default observer(ProductList);
