import {LoadingStageModel} from "models/loadingStage";
import { BaseResponse } from 'types/meta';
import { TgAuthDocument, TgAuthMutation, TgAuthMutationVariables } from 'generated/graphql';
import { IRootStore } from 'types/rootStore';
import { LocalStorageKey } from 'types/localStorage';

export class AuthStore {
  readonly authStage: LoadingStageModel = new LoadingStageModel();
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
  }

  async authorize(): Promise<BaseResponse> {
    if (this.authStage.isLoading) {
      return {
        isError: true
      }
    }

    this.authStage.loading();

    const { data } = await this.rootStore.apolloClient.mutate<TgAuthMutation, TgAuthMutationVariables>({
      mutation: TgAuthDocument,
      variables: { tgWebAppData: window.Telegram.WebApp.initData }
      // variables: { tgWebAppData: "query_id=AAHBjRYFAAAAAMGNFgW6aX_U&user=%7B%22id%22%3A85364161%2C%22first_name%22%3A%22Alexander%22%2C%22last_name%22%3A%22Opryshko%22%2C%22username%22%3A%22alexopryshko%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%7D&auth_date=1661516226&hash=afe6aaf6fb816f9a5128a16f24ea0d446d25e6eedc9617a5b2f0bbf50e51b914" }
    });

    if (!data || !data.tgAuth || !data.tgAuth.data) {
      this.authStage.error();
      return {
        isError: true
      }
    } else {
      this.rootStore.localStorageHandler.setItem(LocalStorageKey.token, data.tgAuth.data.token);

      this.authStage.success();
      return {
        isError: false
      }
    }
  }
}