import { ProductItemFragment } from 'generated/graphql';
import { action, computed, makeObservable, observable } from 'mobx';
import { CartItemInLS, CartItemToEvent } from 'types/cart';
import { ICartStore } from 'types/cartStore';
import { ProductItem } from 'models/product/ProductItem';
import { SelectedAddonsMap } from 'models/product/types';
import addons from 'pages/ProductPage/Addons/Addons';

export class CartItem extends ProductItem {
  private _count: number;
  private cartStore: ICartStore;
  readonly selectedAddons: SelectedAddonsMap;

  constructor(
    {
      count,
      selectedAddons,
      ...rest
    }: Omit<ProductItemFragment, 'count'> & {
      count: number;
      maxCount: number | null;
      selectedAddons: SelectedAddonsMap;
    },
    cartStore: ICartStore
  ) {
    super(rest, cartStore.rootStore.projectStore);

    this.cartStore = cartStore;
    this._count = count;
    this.selectedAddons = selectedAddons;

    makeObservable<CartItem, '_count'>(this, {
      _count: observable,

      count: computed,
      sum: computed,
      incDisabled: computed,

      inc: action,
      dec: action,
    });
  }

  get count(): number {
    return this._count;
  }

  get priceWithAddons(): number {
    const addonsCost = Object.values(this.selectedAddons).reduce(
      (acc, next) =>
        acc +
        Object.values(next.addonOptions).reduce(
          (acc, next) => acc + next.extraCost,
          0
        ),
      0
    );

    return this.price + addonsCost;
  }

  get sum(): number {
    return this.count * this.priceWithAddons;
  }

  get incDisabled(): boolean {
    return this.maxCount !== null && this._count + 1 > this.maxCount;
  }

  inc = (): void => {
    if (this.incDisabled) {
      return;
    }

    this._count++;

    this.cartStore.saveToLS();
  };

  dec = (): void => {
    this._count--;

    this.cartStore.saveToLS();
  };

  toLSFormat(): CartItemInLS {
    return {
      id: this.id,
      count: this.count,
      addons: this.addonsToEvent(),
    };
  }

  addonsToEvent(): Record<string, string[]> {
    return Object.keys(this.selectedAddons).reduce(
      (acc, next) => ({
        ...acc,
        [next]: Object.keys(this.selectedAddons[next].addonOptions),
      }),
      {}
    );
  }

  toEvent(): CartItemToEvent {
    return {
      count: this.count,
      product: {
        id: this.id,
        name: this.name,
        price: this.price,
        addons: this.addonsToEvent(),
      },
    };
  }

  toCreatingCartFormat() {
    return {
      pid: this.id,
      count: this.count,
      opts: Object.values(this.addonsToEvent()).reduce(
        (acc, next) => [...acc, ...next],
        []
      ),
    };
  }

  toText(): string {
    const { currency } = this.cartStore.rootStore.projectStore;
    const base = this.name;

    const color = this.color ? `Цвет: ${this.color}` : null;
    const size = this.size ? `Размер: ${this.size}` : null;

    const colorText = color ? `      ${color}\n` : '';
    const sizeText = size ? `      ${size}\n` : '';

    if (Object.keys(this.selectedAddons).length > 0) {
      const addons = Object.values(this.selectedAddons)
        .map(
          (add) =>
            `  – ${Object.values(add.addonOptions)
              .map((opt) => `${opt.title} (+${opt.extraCost} ${currency})`)
              .join(', ')}`
        )
        .join('\n');

      return `${base}\n${colorText}${sizeText}    ${addons}\n   ${this.count} шт.x ${this.priceWithAddons} ${currency} = ${this.sum} ${currency}`;
    }

    return `${base}\n${colorText}${sizeText}  ${this.count} шт. x ${this.priceWithAddons} ${currency} = ${this.sum} ${currency}`;
  }

  static fromProduct(
    data: ProductItemFragment & {
      countInCart: number;
      selectedAddons: SelectedAddonsMap;
    },
    cartStore: ICartStore
  ): CartItem {
    return new CartItem(
      {
        ...data,
        price: Number(data.price) || 0,
        count: data.countInCart,
        maxCount: typeof data.count === 'number' ? data.count : null,
      },
      cartStore
    );
  }
}
