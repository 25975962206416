import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { CatalogPageTemplate, CategoriesWithPhotoContainer } from '@shopback/ui';

import { useProjectStore } from 'store/globalCtx';
import { CategoryType } from 'store/ProjectStore';
import { TgBackBtn } from 'components/TGBackButton';
import { ProductCategoryChildCategoriesViewModeChoices } from 'generated/graphql';

import CategoryItem from './CategoryItem';
import { ROUTES } from 'config/routes';

const CategoriesList: React.FC = () => {
  const projectStore = useProjectStore();
  const navigate = useNavigate();
  const { project } = projectStore;
  const { categoryId } = useParams();


  let categories: CategoryType[] = [];

  const category = projectStore.getCategoryInProject(categoryId);

  if (categoryId) {
    categories =
      categoryId === 'all'
        ? project?.categories || []
        : category?.childCategories || [];
  }

  const redirectToProducts =
    category?.childCategoriesViewMode ===
    ProductCategoryChildCategoriesViewModeChoices.Horizontal ||
    category?.childCategoriesViewMode ===
    ProductCategoryChildCategoriesViewModeChoices.Inline ||
    categories.length === 0;

  useEffect(() => {
    if (redirectToProducts && categoryId) {
      navigate(ROUTES.products.make(Number(categoryId)), {
        replace: true
      })
    }
  }, [redirectToProducts, categoryId]);

  if (!project || redirectToProducts) {
    return null;
  }

  return (
    <CatalogPageTemplate overflowContent="auto" title={project.mainPageTitle || undefined}>
      {categoryId !== 'all' && <TgBackBtn />}
      <CategoriesWithPhotoContainer categories={categories.map((cat) => (
        <CategoryItem
          key={cat.id}
          category={cat}
        />
      ))}/>
    </CatalogPageTemplate>
  );
};

export default CategoriesList;
