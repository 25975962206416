import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AddToCartItem } from '@shopback/ui';
import { useProjectStore, useRootStore } from 'store/globalCtx';
import { ROUTES } from 'config/routes';
import CountSelector from 'components/CountSelector';
import { ProductItem } from 'models/product';
import { SelectedAddonsMap } from 'models/product/types';
import { observer } from 'mobx-react';
import { ProjectProjectCartModeChoices } from 'generated/graphql';

type Props = {
  product: ProductItem;
  addons?: SelectedAddonsMap;
  inCartView?: boolean;
  isSmall?: boolean;
};

const ProductCartActions: React.FC<Props> = ({
  product,
  addons = {},
  inCartView = false,
  isSmall = false,
}: Props) => {
  const cartStore = useRootStore().cartStore;
  const projectStore = useProjectStore();
  const project = projectStore.project;
  const navigate = useNavigate();

  const cartItem =
    cartStore.findItem(product.id, addons) || cartStore.findProduct(product.id);

  const inCart = !!cartItem;

  const hasRequiredAddons = product.hasRequiredAddons;
  const singleMode = project?.cartMode === ProjectProjectCartModeChoices.Single;

  const onAddToCart = () => {
    if (!project) {
      return null;
    }

    if (hasRequiredAddons || singleMode) {
      return navigate(ROUTES.product.make(product.id));
    }

    cartStore.addToCart(product, addons);
  };

  const onCartInc = () => {
    if (hasRequiredAddons || singleMode) {
      return navigate(ROUTES.product.make(product.id));
    }

    cartStore.incCartCount(product, addons);
  };

  if (!inCart) {
    return (
      <AddToCartItem onClick={onAddToCart} isSmall={isSmall} caption={project?.productInListCallToActionTitle || undefined} />
    );
  } else {
    return (
      <CountSelector
        incDisabled={cartItem?.incDisabled}
        value={
          inCartView ? cartItem?.count : cartStore.getProductCount(product.id)
        }
        onInc={onCartInc}
        onDec={() => cartStore.decCartCount(product, addons)}
      />
    );
  }
};

export default observer(ProductCartActions);
