import React, { useContext } from 'react';
import { RootStore } from './RootStore';
import { ProjectStore } from './ProjectStore';
import { CartStore } from './CartStore';

export const rootStore = new RootStore();

export const GlobalCtx = React.createContext(rootStore);

export const useRootStore = (): RootStore => {
  const data = useContext(GlobalCtx);

  if (!data) {
    throw new Error('Use GlobalCtx inside Provider!');
  }

  return data;
};

export const useProjectStore = (): ProjectStore => {
  return useRootStore().projectStore;
};

export const useCartStore = (): CartStore => {
  return useRootStore().cartStore;
};
