import React from 'react';
import { Button, IconButton, Stack } from 'rsuite';
import { SIZE } from 'components/ui';
import { ModifiersWrapper } from 'pages/ProductPage/styles';
import { ProductItem } from 'models/product';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'config/routes';
import { ProductOptionType } from 'generated/graphql';

type Props = {
  product: ProductItem;
};

const Modifications: React.FC<Props> = ({ product }: Props) => {
  const navigate = useNavigate();

  if (
    !product.productModifications ||
    product.productModifications.length === 0
  ) {
    return null;
  }

  const currentColor = product.color;
  const currentSize = product.size;

  const onSelectColor = (c: ProductOptionType) => {
    const coloredProduct = product.productModifications?.find(
      (m) => m.productOptions?.color?.key === c.key
    )?.id;

    if (coloredProduct) {
      navigate(ROUTES.product.make(String(coloredProduct)));
    } else {
      console.error('no modification with color', c);
    }
  };

  const onSelectSize = (s: ProductOptionType) => {
    const sizedProduct = product.productModifications?.find(
      (m) =>
        m.productOptions?.color?.key === currentColor &&
        m.productOptions?.size?.key === s.key
    )?.id;

    if (sizedProduct) {
      navigate(ROUTES.product.make(sizedProduct));
    } else {
      console.error('no modification with size', s);
    }
  };

  const modificationsToFilter = currentColor
    ? product.productModifications.filter(
        (mod) => mod.productOptions?.color?.key === currentColor
      ) || []
    : product.productModifications;

  const availableSizes = [
    ...new Set(modificationsToFilter.map((next) => next.productOptions?.size)),
  ].filter(Boolean);

  return (
    <div>
      {product.colorOptions.length > 0 && (
        <ModifiersWrapper>
          <Stack wrap spacing={SIZE.m} alignItems="center">
            <label>
              <b>Цвета:</b>
            </label>
            {product.colorOptions.map((c) => (
              <IconButton
                size={c.key === currentColor ? 'lg' : 'sm'}
                onClick={() => onSelectColor(c)}
                circle
                style={{ background: c.key }}
                key={c.key}
              />
            ))}
          </Stack>
        </ModifiersWrapper>
      )}
      {availableSizes.length > 0 && (
        <ModifiersWrapper>
          <Stack wrap spacing={SIZE.s} alignItems="center">
            <label>
              <b>Размеры:</b>
            </label>
            {availableSizes.map((c) =>
              c ? (
                <Button
                  appearance={c.key === currentSize ? 'primary' : 'default'}
                  key={c.key}
                  onClick={() => onSelectSize(c)}
                >
                  {c.key}
                </Button>
              ) : null
            )}
          </Stack>
        </ModifiersWrapper>
      )}
    </div>
  );
};

export default Modifications;
