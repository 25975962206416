import { AuthStore } from './AuthStore';
import { LocalStorageHandler } from './LocalStorageHandler';
import { ApolloClientStore } from './ApolloClientStore';
import { IRootStore } from 'types/rootStore';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { LocalStorageKey } from 'types/localStorage';
import { LoadingStageModel } from 'models/loadingStage';
import { BaseResponse } from 'types/meta';
import { ProjectStore } from './ProjectStore';
import { CartStore } from './CartStore';

export class RootStore implements IRootStore {
  readonly cartStore: CartStore = new CartStore(this);
  readonly authStore: AuthStore = new AuthStore(this);
  readonly localStorageHandler: LocalStorageHandler = new LocalStorageHandler();
  readonly apolloClientStore: ApolloClientStore = new ApolloClientStore(this);
  readonly projectStore: ProjectStore = new ProjectStore(this);

  readonly initStage: LoadingStageModel = new LoadingStageModel();

  get apolloClient(): ApolloClient<NormalizedCacheObject> {
    return this.apolloClientStore.client;
  }

  async init(): Promise<BaseResponse> {
    if (this.initStage.isLoading) {
      return {
        isError: true
      }
    }

    this.initStage.loading();

    this.initApp();
    // const response = await this.authStore.authorize();

    const projectResponse = await this.projectStore.load();

    if (projectResponse.isError) {
      this.initStage.error();

      return {
        isError: true
      }
    }

    this.cartStore.init();

    this.initStage.success();

    return {
      isError: false
    }
  }

  private initApp() {
    const params = new URLSearchParams(window.location.search);

    window.openData = Object.fromEntries(params);

    this.localStorageHandler.setItem(LocalStorageKey.project, params.get('project') || '');

    window.Telegram.WebApp.ready();
    // @ts-ignore
    Telegram.WebApp.enableClosingConfirmation();
  }
}
