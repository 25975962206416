import React from 'react';
import { CategoryWithPhoto } from '@shopback/ui';

import { CategoryType } from 'store/ProjectStore';
import { Link } from "react-router-dom";
import { ROUTES } from "config/routes";

type Props = {
  category: CategoryType;
};

const CategoryItem: React.FC<Props> = ({ category }: Props) => {
  if (!category.attachment) {
    return null;
  }

  return (
    <Link to={ROUTES.categories.make(category.id)}>
      <CategoryWithPhoto id={category.id} name={category.name} attachment={{
        src: category.attachment?.smallImage.url || category.attachment.file,
        width: category.attachment?.smallImage?.width || undefined,
        height: category.attachment?.smallImage?.height || undefined,
      }} />
    </Link>
  );
};

export default CategoryItem;
