import React from 'react';
import { CartPageTemplate } from '@shopback/ui';
import { List, Typography } from 'antd';
import ProductItem from 'pages/ProductList/ProductItem';
import { useCartStore, useProjectStore } from 'store/globalCtx';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ROUTES } from 'config/routes';
import { ProjectProjectViewModeChoices } from 'generated/graphql';
import { useTGBackButton } from 'components/TGBackButton';

const Cart: React.FC = () => {
  useTGBackButton();

  const cartStore = useCartStore();
  const projectStore = useProjectStore();
  const project = projectStore.project;

  if (!project) {
    return null;
  }

  return (
        <CartPageTemplate title={project.cartTitle || undefined} items={<List
          itemLayout="horizontal"
          dataSource={cartStore.items}
          locale={{
            emptyText: (
              <>
                <Typography.Text type="secondary">
                  Ничего не выбрано
                </Typography.Text>
                <p>
                  <Link to={ROUTES.main.index}>Вернуться к выбору</Link>
                </p>
              </>
            ),
          }}
          renderItem={(item) => (
            <ProductItem
              inCartView
              product={item}
              addons={item.selectedAddons}
              size={ProjectProjectViewModeChoices.Small}
            />
          )}
        />}
          sumPrice={!cartStore.isEmpty && cartStore.orderSum > 0 ? { value: cartStore.orderSum, currency: projectStore.currency} : undefined}
          button={{
            onClick: cartStore.buy,
            title: project.finishButtonTitle || undefined
          }}
        />
  );
};

export default observer(Cart);
