import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { LocalStorageKey } from 'types/localStorage';
import { IRootStore } from 'types/rootStore';

const isProd = process.env.NODE_ENV === 'production';

export class ApolloClientStore {
  readonly client: ApolloClient<NormalizedCacheObject>;
  private rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    const httpLink = createHttpLink({
      uri: isProd
        ? 'https://shopback.ru-prod2.kts.studio/graphql'
        : 'https://shopback.ru-prod2.kts.studio/graphql',
    });

    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem(LocalStorageKey.token);
      return {
        headers: {
          ...headers,
          // 'x-project': 'b1bb7476-0b0e-431b-acb9-65c8fa9d96b2',
          'x-project': localStorage.getItem(LocalStorageKey.project), // '8129b814-c7ac-40ac-a057-fd9073f43d37'
          ...(token ? { 'x-token': token } : {}),
        },
      };
    });

    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }
}
