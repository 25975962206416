import styled from 'styled-components';
import { Button } from 'antd';
import Title from 'antd/lib/typography/Title';

export const SIZE = {
  s: 12,
  m: 16,
  l: 24,
  xl: 32,
};

export const COLORS = {
  color2: '#bae7ff',
  gray5: '#d9d9d9',
  gray6: '#6B6B6B',
  black: '#000000',
};

export const BackBtn = styled(Button).attrs({ shape: 'circle' })`
  margin-right: ${SIZE.m}px;
`;

export const FooterFixed = styled.div`
  position: fixed;
  bottom: 0;
  padding: ${SIZE.s}px;
  left: 0;
  width: 100%;
  background: white;
  border-top: 1px solid ${COLORS.gray5};
  z-index: 16;
`;

export const Space = styled.div`
  margin-bottom: ${SIZE.s}px;
`;

export const TransitionPage = styled.div`
  height: 100%;
  overflow: auto;
`;
