import React, { useState } from 'react';
import { Skeleton } from 'antd';
import { FooterFixed } from 'components/ui';
import { useNavigate, useParams } from 'react-router-dom';
import {PageTemplate, Button, ProductPageTemplate} from "@shopback/ui";
import { useProjectStore, useRootStore } from 'store/globalCtx';
import { observer } from 'mobx-react';
import { ROUTES } from 'config/routes';
import { useTGBackButton } from 'components/TGBackButton';
import CarouselWrapper from 'components/CarouselWrapper';

import { ProductPageStore } from './store';
import CardImg from 'components/CardImg';
import Modifications from 'pages/ProductPage/Modifications';
import Addons from 'pages/ProductPage/Addons/Addons';
import CartActions from 'pages/ProductPage/CartActions';

const ProductPage: React.FC = () => {
  useTGBackButton();
  const { id } = useParams();
  const rootStore = useRootStore();
  const cartStore = rootStore.cartStore;
  const [store] = useState(() => new ProductPageStore(rootStore));

  React.useEffect(() => {
    store.load(Number(id));
  }, [id]);

  const navigate = useNavigate();
  const projectStore = useProjectStore();
  const project = projectStore.project;

  if (!project) {
    return null;
  }

  if (store.loadingStage.isLoading) {
    return (
      <PageTemplate>
        <Skeleton active avatar paragraph={{ rows: 24 }} />
      </PageTemplate>
    );
  }

  const product = store.product.value;

  if (!product) {
    return null;
  }

  return (
    <ProductPageTemplate
      attachments={<CarouselWrapper autoplay>
      {product.attachments.map((img) => (
        <CardImg
          key={img.file}
          src={img.bigImage.url || img.file}
          height={img.bigImage.height || undefined}
          width={img.bigImage.width || undefined}
        />
      ))}
    </CarouselWrapper>}
      name={product.name}
      modifications={<Modifications product={product} />}
      description={product.description || undefined}
      price={product.isFree ? undefined : product.localePrice}
      addons={<Addons product={product} store={store} />}
      actions={<CartActions product={product} store={store} />}
      button={cartStore.isEmpty ? undefined : {
        onClick: () => navigate(ROUTES.cart),
        title: project.cartButtonTitle || undefined
      }}
      />
  );
};

export default observer(ProductPage);
