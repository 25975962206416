import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Decimal: any;
  GenericScalar: any;
  UUID: any;
};

export type AddonOptionType = {
  __typename?: 'AddonOptionType';
  addon: AddonType;
  extraCost: Scalars['Int'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type AddonType = {
  __typename?: 'AddonType';
  addonOptions: Array<AddonOptionType>;
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  order: Scalars['Int'];
  products: Array<ProductType>;
  title: Scalars['String'];
  type: ProductAddonTypeChoices;
};

export type CategoryListType = {
  __typename?: 'CategoryListType';
  categories: Array<CategoryType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type CategoryMutationInput = {
  attachment?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  parentExternalId?: InputMaybe<Scalars['String']>;
  project: Scalars['String'];
};

export type CategoryMutationResponse = {
  __typename?: 'CategoryMutationResponse';
  data?: Maybe<CategoryType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type CategoryType = {
  __typename?: 'CategoryType';
  attachment?: Maybe<ImageAttachmentType>;
  childCategories: Array<CategoryType>;
  childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  parent?: Maybe<CategoryType>;
  parentIds?: Maybe<Array<Scalars['BigInt']>>;
  products: Array<ProductType>;
  project: ProjectType;
};

export type CreateOrderMutationInput = {
  products: Scalars['String'];
  project: Scalars['String'];
};

export type CreateOrderMutationResponse = {
  __typename?: 'CreateOrderMutationResponse';
  data?: Maybe<OrderType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type FieldProblemType = {
  __typename?: 'FieldProblemType';
  errCode: Scalars['String'];
  errDescription: Scalars['String'];
  fieldName: Scalars['String'];
};

export type GqErrorType = {
  __typename?: 'GqErrorType';
  data?: Maybe<Scalars['GenericScalar']>;
  fieldProblems?: Maybe<Array<FieldProblemType>>;
  message: Scalars['String'];
  status: Scalars['String'];
};

export type ImageAttachmentType = {
  __typename?: 'ImageAttachmentType';
  bigImage: ImageType;
  file: Scalars['String'];
  id: Scalars['ID'];
  smallImage: ImageType;
};

export type ImageType = {
  __typename?: 'ImageType';
  height?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type LinkedProductType = {
  __typename?: 'LinkedProductType';
  attachments: Array<ImageAttachmentType>;
  /** можно оставить пустым, тогда товар можно купить бесконечное количество раз */
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<ProductType>;
  parentLinkedProducts: Array<ProductType>;
  price: Scalars['Decimal'];
  productAddons: Array<AddonType>;
  productModifications: Array<ProductType>;
  productOptions: Array<ProductOptionType>;
  productType: ProductProductProductTypeChoices;
};

export type ModificationMutationInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['String']>;
  price: Scalars['Decimal'];
};

export type ModificationMutationResponse = {
  __typename?: 'ModificationMutationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type ModificationType = {
  __typename?: 'ModificationType';
  /** можно оставить пустым, тогда товар можно купить бесконечное количество раз */
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  price: Scalars['Decimal'];
  productOptions: ProductOptionDict;
};

export type Mutation = {
  __typename?: 'Mutation';
  category?: Maybe<CategoryMutationResponse>;
  createOrder?: Maybe<CreateOrderMutationResponse>;
  modification?: Maybe<ModificationMutationResponse>;
  product?: Maybe<ProductMutationResponse>;
  productOptions?: Maybe<OptionsMutationResponse>;
  productRelation?: Maybe<ProductRelationResponse>;
  tgAuth?: Maybe<TgAuthResponse>;
};


export type MutationCategoryArgs = {
  input: CategoryMutationInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderMutationInput;
};


export type MutationModificationArgs = {
  input: ModificationMutationInput;
};


export type MutationProductArgs = {
  input: ProductMutationInput;
};


export type MutationProductOptionsArgs = {
  input: OptionsMutationInput;
};


export type MutationProductRelationArgs = {
  input: ProductRelationInput;
};


export type MutationTgAuthArgs = {
  input: TgAuthInput;
};

export type OptionsMutationInput = {
  addonIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  colorOption?: InputMaybe<Scalars['String']>;
  product: Scalars['Int'];
  sizeOption?: InputMaybe<Scalars['String']>;
};

export type OptionsMutationResponse = {
  __typename?: 'OptionsMutationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type OrderType = {
  __typename?: 'OrderType';
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  project: ProjectType;
  token: Scalars['UUID'];
};

/** An enumeration. */
export enum ProductAddonTypeChoices {
  /** multiple */
  Multiple = 'MULTIPLE',
  /** single */
  Single = 'SINGLE'
}

/** An enumeration. */
export enum ProductCategoryChildCategoriesViewModeChoices {
  /** horizontal */
  Horizontal = 'HORIZONTAL',
  /** inherit */
  Inherit = 'INHERIT',
  /** inline */
  Inline = 'INLINE',
  /** photo */
  Photo = 'PHOTO'
}

export type ProductListFiltersType = {
  category?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type ProductListType = {
  __typename?: 'ProductListType';
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
  products: Array<ProductType>;
};

export type ProductMutationInput = {
  attachments: Array<InputMaybe<Scalars['Int']>>;
  categoryExternalId?: InputMaybe<Scalars['String']>;
  categoryName: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Decimal'];
  project: Scalars['String'];
};

export type ProductMutationResponse = {
  __typename?: 'ProductMutationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type ProductOptionDict = {
  __typename?: 'ProductOptionDict';
  color?: Maybe<ProductOptionType>;
  size?: Maybe<ProductOptionType>;
};

export type ProductOptionType = {
  __typename?: 'ProductOptionType';
  /** значение для типа "цвет" - rgb код #f0f0f0 */
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type ProductOptionsSet = {
  __typename?: 'ProductOptionsSet';
  color?: Maybe<Array<ProductOptionType>>;
  size?: Maybe<Array<ProductOptionType>>;
};

/** An enumeration. */
export enum ProductProductProductTypeChoices {
  /** modification */
  Modification = 'MODIFICATION',
  /** product */
  Product = 'PRODUCT'
}

export type ProductRelationInput = {
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  notifyAboutDiscount?: InputMaybe<Scalars['Boolean']>;
  productId: Scalars['Int'];
};

export type ProductRelationResponse = {
  __typename?: 'ProductRelationResponse';
  data?: Maybe<ProductType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type ProductType = {
  __typename?: 'ProductType';
  addons?: Maybe<Array<AddonType>>;
  attachments: Array<ImageAttachmentType>;
  category?: Maybe<CategoryType>;
  /** можно оставить пустым, тогда товар можно купить бесконечное количество раз */
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  linkedProducts: Array<LinkedProductType>;
  name: Scalars['String'];
  parent?: Maybe<ProductType>;
  parentLinkedProducts: Array<ProductType>;
  price: Scalars['Decimal'];
  productAddons: Array<AddonType>;
  productModifications?: Maybe<Array<ModificationType>>;
  productOptions: ProductOptionDict;
  productOptionsSet: ProductOptionsSet;
  productType: ProductProductProductTypeChoices;
  project: ProjectType;
  userRelation?: Maybe<RelationType>;
};

/** An enumeration. */
export enum ProjectProjectCartModeChoices {
  /** multiple */
  Multiple = 'MULTIPLE',
  /** one_item */
  OneItem = 'ONE_ITEM',
  /** single */
  Single = 'SINGLE'
}

/** An enumeration. */
export enum ProjectProjectCategoryViewModeChoices {
  /** horizontal */
  Horizontal = 'HORIZONTAL',
  /** inline */
  Inline = 'INLINE',
  /** photo */
  Photo = 'PHOTO'
}

/** An enumeration. */
export enum ProjectProjectViewModeChoices {
  /** large */
  Large = 'LARGE',
  /** medium */
  Medium = 'MEDIUM',
  /** small */
  Small = 'SMALL'
}

export type ProjectType = {
  __typename?: 'ProjectType';
  cartButtonTitle?: Maybe<Scalars['String']>;
  cartMode: ProjectProjectCartModeChoices;
  cartTitle?: Maybe<Scalars['String']>;
  categories: Array<CategoryType>;
  categoryViewMode: ProjectProjectCategoryViewModeChoices;
  currency?: Maybe<Scalars['String']>;
  finishButtonTitle?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mainPageTitle?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  orderSet: Array<OrderType>;
  productCallToActionTitle?: Maybe<Scalars['String']>;
  productInListCallToActionTitle?: Maybe<Scalars['String']>;
  products: Array<ProductType>;
  showCategory: Scalars['Boolean'];
  showSearch: Scalars['Boolean'];
  supportElectronicGoods: Scalars['Boolean'];
  supportModification: Scalars['Boolean'];
  tgToken: Scalars['String'];
  uuid: Scalars['UUID'];
  viewMode: ProjectProjectViewModeChoices;
};

export type Query = {
  __typename?: 'Query';
  categoriesList?: Maybe<CategoryListType>;
  productList?: Maybe<ProductListType>;
  project?: Maybe<ProjectType>;
};


export type QueryCategoriesListArgs = {
  parent?: InputMaybe<Scalars['Int']>;
};


export type QueryProductListArgs = {
  filters?: InputMaybe<ProductListFiltersType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type RelationType = {
  __typename?: 'RelationType';
  isFavourite: Scalars['Boolean'];
  notifyAboutDiscount: Scalars['Boolean'];
  product: ProductType;
};

export type TgAuthInput = {
  tgWebAppData: Scalars['String'];
};

export type TgAuthResponse = {
  __typename?: 'TgAuthResponse';
  data?: Maybe<TgAuthType>;
  errors?: Maybe<Array<Maybe<GqErrorType>>>;
};

export type TgAuthType = {
  __typename?: 'TgAuthType';
  tgId: Scalars['Int'];
  token: Scalars['String'];
};

export type ProjectInfoFragment = { __typename?: 'ProjectType', id: string, name: string, viewMode: ProjectProjectViewModeChoices, showSearch: boolean, showCategory: boolean, productCallToActionTitle?: string | null, productInListCallToActionTitle?: string | null, finishButtonTitle?: string | null, cartButtonTitle?: string | null, cartTitle?: string | null, mainPageTitle?: string | null, cartMode: ProjectProjectCartModeChoices, currency?: string | null, categoryViewMode: ProjectProjectCategoryViewModeChoices, categories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }> };

export type ProductOptionFragment = { __typename?: 'ProductOptionType', value?: string | null, key: string };

export type ProductItemDataFragment = { __typename?: 'ProductType', id: string, description?: string | null, price: any, count?: number | null };

export type ProductOptionsFragment = { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null };

export type ProductModificationFragment = { __typename?: 'ModificationType', id: string, description?: string | null, price: any, count?: number | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null } };

export type ImageTypeFragment = { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null };

export type ImageAttachmentFragment = { __typename?: 'ImageAttachmentType', file: string, bigImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null }, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } };

export type AddonOptionFragment = { __typename?: 'AddonOptionType', title: string, id: string, extraCost: number };

export type AddonFragment = { __typename?: 'AddonType', isRequired: boolean, type: ProductAddonTypeChoices, title: string, id: string, order: number, addonOptions: Array<{ __typename?: 'AddonOptionType', title: string, id: string, extraCost: number }> };

export type ProductItemFragment = { __typename?: 'ProductType', name: string, id: string, description?: string | null, price: any, count?: number | null, attachments: Array<{ __typename?: 'ImageAttachmentType', file: string, bigImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null }, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } }>, addons?: Array<{ __typename?: 'AddonType', isRequired: boolean, type: ProductAddonTypeChoices, title: string, id: string, order: number, addonOptions: Array<{ __typename?: 'AddonOptionType', title: string, id: string, extraCost: number }> }> | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null }, productModifications?: Array<{ __typename?: 'ModificationType', id: string, description?: string | null, price: any, count?: number | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null } }> | null, productOptionsSet: { __typename?: 'ProductOptionsSet', color?: Array<{ __typename?: 'ProductOptionType', value?: string | null, key: string }> | null, size?: Array<{ __typename?: 'ProductOptionType', value?: string | null, key: string }> | null } };

export type CategoryFieldsFragment = { __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null };

export type CategoryInfoFragment = { __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null };

export type GetProductListQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<ProductListFiltersType>;
}>;


export type GetProductListQuery = { __typename?: 'Query', productList?: { __typename?: 'ProductListType', count?: number | null, products: Array<{ __typename?: 'ProductType', name: string, id: string, description?: string | null, price: any, count?: number | null, attachments: Array<{ __typename?: 'ImageAttachmentType', file: string, bigImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null }, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } }>, addons?: Array<{ __typename?: 'AddonType', isRequired: boolean, type: ProductAddonTypeChoices, title: string, id: string, order: number, addonOptions: Array<{ __typename?: 'AddonOptionType', title: string, id: string, extraCost: number }> }> | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null }, productModifications?: Array<{ __typename?: 'ModificationType', id: string, description?: string | null, price: any, count?: number | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null } }> | null, productOptionsSet: { __typename?: 'ProductOptionsSet', color?: Array<{ __typename?: 'ProductOptionType', value?: string | null, key: string }> | null, size?: Array<{ __typename?: 'ProductOptionType', value?: string | null, key: string }> | null } }>, errors?: Array<{ __typename?: 'GqErrorType', status: string, message: string } | null> | null } | null };

export type GetProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetProductQuery = { __typename?: 'Query', productList?: { __typename?: 'ProductListType', count?: number | null, products: Array<{ __typename?: 'ProductType', name: string, id: string, description?: string | null, price: any, count?: number | null, attachments: Array<{ __typename?: 'ImageAttachmentType', file: string, bigImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null }, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } }>, addons?: Array<{ __typename?: 'AddonType', isRequired: boolean, type: ProductAddonTypeChoices, title: string, id: string, order: number, addonOptions: Array<{ __typename?: 'AddonOptionType', title: string, id: string, extraCost: number }> }> | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null }, productModifications?: Array<{ __typename?: 'ModificationType', id: string, description?: string | null, price: any, count?: number | null, productOptions: { __typename?: 'ProductOptionDict', color?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null, size?: { __typename?: 'ProductOptionType', value?: string | null, key: string } | null } }> | null, productOptionsSet: { __typename?: 'ProductOptionsSet', color?: Array<{ __typename?: 'ProductOptionType', value?: string | null, key: string }> | null, size?: Array<{ __typename?: 'ProductOptionType', value?: string | null, key: string }> | null } }>, errors?: Array<{ __typename?: 'GqErrorType', status: string, message: string } | null> | null } | null };

export type TgAuthMutationVariables = Exact<{
  tgWebAppData: Scalars['String'];
}>;


export type TgAuthMutation = { __typename?: 'Mutation', tgAuth?: { __typename?: 'TgAuthResponse', data?: { __typename?: 'TgAuthType', token: string } | null, errors?: Array<{ __typename?: 'GqErrorType', status: string, message: string, fieldProblems?: Array<{ __typename?: 'FieldProblemType', fieldName: string, errDescription: string }> | null } | null> | null } | null };

export type CreateOrderMutationVariables = Exact<{
  project: Scalars['String'];
  products: Scalars['String'];
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'CreateOrderMutationResponse', data?: { __typename?: 'OrderType', token: any } | null, errors?: Array<{ __typename?: 'GqErrorType', status: string, message: string, fieldProblems?: Array<{ __typename?: 'FieldProblemType', fieldName: string, errDescription: string }> | null } | null> | null } | null };

export type GetProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProjectQuery = { __typename?: 'Query', project?: { __typename?: 'ProjectType', id: string, name: string, viewMode: ProjectProjectViewModeChoices, showSearch: boolean, showCategory: boolean, productCallToActionTitle?: string | null, productInListCallToActionTitle?: string | null, finishButtonTitle?: string | null, cartButtonTitle?: string | null, cartTitle?: string | null, mainPageTitle?: string | null, cartMode: ProjectProjectCartModeChoices, currency?: string | null, categoryViewMode: ProjectProjectCategoryViewModeChoices, categories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, childCategories: Array<{ __typename?: 'CategoryType', id: string, name: string, childCategoriesViewMode: ProductCategoryChildCategoriesViewModeChoices, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }>, attachment?: { __typename?: 'ImageAttachmentType', file: string, smallImage: { __typename?: 'ImageType', url?: string | null, width?: number | null, height?: number | null } } | null }> } | null };

export const ImageTypeFragmentDoc = gql`
    fragment ImageType on ImageType {
  url
  width
  height
}
    `;
export const CategoryFieldsFragmentDoc = gql`
    fragment CategoryFields on CategoryType {
  id
  name
  childCategoriesViewMode
  attachment {
    file
    smallImage {
      ...ImageType
    }
  }
}
    ${ImageTypeFragmentDoc}`;
export const CategoryInfoFragmentDoc = gql`
    fragment CategoryInfo on CategoryType {
  ...CategoryFields
  childCategories {
    ...CategoryFields
    childCategories {
      ...CategoryFields
      childCategories {
        ...CategoryFields
      }
    }
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const ProjectInfoFragmentDoc = gql`
    fragment ProjectInfo on ProjectType {
  id
  name
  viewMode
  showSearch
  showCategory
  productCallToActionTitle
  productInListCallToActionTitle
  finishButtonTitle
  cartButtonTitle
  cartTitle
  mainPageTitle
  cartMode
  currency
  categoryViewMode
  categories {
    ...CategoryInfo
  }
}
    ${CategoryInfoFragmentDoc}`;
export const ImageAttachmentFragmentDoc = gql`
    fragment ImageAttachment on ImageAttachmentType {
  file
  bigImage {
    ...ImageType
  }
  smallImage {
    ...ImageType
  }
}
    ${ImageTypeFragmentDoc}`;
export const ProductItemDataFragmentDoc = gql`
    fragment ProductItemData on ProductType {
  id
  description
  price
  count
}
    `;
export const AddonOptionFragmentDoc = gql`
    fragment AddonOption on AddonOptionType {
  title
  id
  extraCost
}
    `;
export const AddonFragmentDoc = gql`
    fragment Addon on AddonType {
  addonOptions {
    ...AddonOption
  }
  isRequired
  type
  title
  id
  order
}
    ${AddonOptionFragmentDoc}`;
export const ProductOptionFragmentDoc = gql`
    fragment ProductOption on ProductOptionType {
  value
  key
}
    `;
export const ProductOptionsFragmentDoc = gql`
    fragment ProductOptions on ProductOptionDict {
  color {
    ...ProductOption
  }
  size {
    ...ProductOption
  }
}
    ${ProductOptionFragmentDoc}`;
export const ProductModificationFragmentDoc = gql`
    fragment ProductModification on ModificationType {
  id
  description
  price
  count
  productOptions {
    ...ProductOptions
  }
}
    ${ProductOptionsFragmentDoc}`;
export const ProductItemFragmentDoc = gql`
    fragment ProductItem on ProductType {
  name
  attachments {
    ...ImageAttachment
  }
  ...ProductItemData
  addons {
    ...Addon
  }
  productOptions {
    ...ProductOptions
  }
  productModifications {
    ...ProductModification
  }
  productOptionsSet {
    color {
      ...ProductOption
    }
    size {
      ...ProductOption
    }
  }
}
    ${ImageAttachmentFragmentDoc}
${ProductItemDataFragmentDoc}
${AddonFragmentDoc}
${ProductOptionsFragmentDoc}
${ProductModificationFragmentDoc}
${ProductOptionFragmentDoc}`;
export const GetProductListDocument = gql`
    query getProductList($offset: Int, $limit: Int, $filters: ProductListFiltersType) {
  productList(limit: $limit, filters: $filters, offset: $offset) {
    count
    products {
      ...ProductItem
    }
    errors {
      status
      message
    }
  }
}
    ${ProductItemFragmentDoc}`;

/**
 * __useGetProductListQuery__
 *
 * To run a query within a React component, call `useGetProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetProductListQuery(baseOptions?: Apollo.QueryHookOptions<GetProductListQuery, GetProductListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductListQuery, GetProductListQueryVariables>(GetProductListDocument, options);
      }
export function useGetProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductListQuery, GetProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductListQuery, GetProductListQueryVariables>(GetProductListDocument, options);
        }
export type GetProductListQueryHookResult = ReturnType<typeof useGetProductListQuery>;
export type GetProductListLazyQueryHookResult = ReturnType<typeof useGetProductListLazyQuery>;
export type GetProductListQueryResult = Apollo.QueryResult<GetProductListQuery, GetProductListQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: Int!) {
  productList(filters: {ids: [$id]}) {
    count
    products {
      ...ProductItem
    }
    errors {
      status
      message
    }
  }
}
    ${ProductItemFragmentDoc}`;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const TgAuthDocument = gql`
    mutation tgAuth($tgWebAppData: String!) {
  tgAuth(input: {tgWebAppData: $tgWebAppData}) {
    data {
      token
    }
    errors {
      status
      message
      fieldProblems {
        fieldName
        errDescription
      }
    }
  }
}
    `;
export type TgAuthMutationFn = Apollo.MutationFunction<TgAuthMutation, TgAuthMutationVariables>;

/**
 * __useTgAuthMutation__
 *
 * To run a mutation, you first call `useTgAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTgAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tgAuthMutation, { data, loading, error }] = useTgAuthMutation({
 *   variables: {
 *      tgWebAppData: // value for 'tgWebAppData'
 *   },
 * });
 */
export function useTgAuthMutation(baseOptions?: Apollo.MutationHookOptions<TgAuthMutation, TgAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TgAuthMutation, TgAuthMutationVariables>(TgAuthDocument, options);
      }
export type TgAuthMutationHookResult = ReturnType<typeof useTgAuthMutation>;
export type TgAuthMutationResult = Apollo.MutationResult<TgAuthMutation>;
export type TgAuthMutationOptions = Apollo.BaseMutationOptions<TgAuthMutation, TgAuthMutationVariables>;
export const CreateOrderDocument = gql`
    mutation createOrder($project: String!, $products: String!) {
  createOrder(input: {project: $project, products: $products}) {
    data {
      token
    }
    errors {
      status
      message
      fieldProblems {
        fieldName
        errDescription
      }
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      project: // value for 'project'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const GetProjectDocument = gql`
    query getProject {
  project {
    categories {
      ...CategoryInfo
    }
    ...ProjectInfo
  }
}
    ${CategoryInfoFragmentDoc}
${ProjectInfoFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;