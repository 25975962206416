import { Carousel } from 'antd';
import styled from 'styled-components';
import {COLORS} from "components/ui";

const CarouselWrapper = styled(Carousel)`
  > .slick-dots-bottom {
    bottom: -25px;
  }
  > .slick-list .slick-track  {
    display: flex;
    align-items: center;
  }
  > .slick-dots li button {
    background: ${COLORS.gray6};
  }
  > .slick-dots li.slick-active button {
    background: ${COLORS.black};
  }
`;

export default CarouselWrapper;