import React from 'react';
import { ProductItem } from 'models/product';
import { ButtonToolbar, IconButton, Panel, Stack, Tag } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import CheckIcon from '@rsuite/icons/Check';
import { AddonFragment } from 'generated/graphql';
import AddonModal from 'pages/ProductPage/Addons/AddonModal';
import { observer } from 'mobx-react';
import { ProductPageStore } from 'pages/ProductPage/store';
import { Typography } from 'antd';
import { SIZE, Space } from 'components/ui';
import { useProjectStore } from 'store/globalCtx';
import { getLocalePrice } from '@shopback/ui';

type Props = {
  product: ProductItem;
  store: ProductPageStore;
};

const Addons: React.FC<Props> = ({ product, store }: Props) => {
  const projectStore = useProjectStore();

  const [selectedAddon, setSelectedAddon] =
    React.useState<AddonFragment | null>(null);
  const [showModal, setShowModal] = React.useState(false);

  const toggle = React.useCallback((selectedAddon: AddonFragment | null) => {
    if (!selectedAddon) {
      return setShowModal(false);
    }
    setSelectedAddon(selectedAddon);
    setShowModal(true);
  }, []);

  const close = React.useCallback(() => toggle(null), []);

  if (!product.productAddons) {
    return null;
  }

  return (
    <>
      <AddonModal
        product={product}
        addon={selectedAddon}
        open={showModal}
        close={close}
        store={store}
      />
      {product.productAddons?.map((addon) => {
        const isSelected = store.checkAddon(addon);

        return (
          <Space>
            <Panel key={addon.id} shaded>
              <Space>
                <Stack spacing={SIZE.m}>
                  {addon.isRequired && !isSelected && (
                    <Tag color="orange">!</Tag>
                  )}
                  <Stack.Item grow={1}>
                    <IconButton
                      block
                      appearance={isSelected ? 'ghost' : 'default'}
                      icon={isSelected ? <CheckIcon /> : <PlusIcon />}
                      placement="right"
                      style={{ textAlign: 'left' }}
                      onClick={() => toggle(addon)}
                    >
                      {addon.title}
                    </IconButton>
                  </Stack.Item>
                </Stack>
              </Space>
              {Object.values(
                store.selectedAddons[addon.id]?.addonOptions || {}
              ).map((option) => (
                <Space>
                  <Typography.Text type="secondary">
                    {option.title} (+ {getLocalePrice({ price: option.extraCost, currency: projectStore.currency})})
                  </Typography.Text>
                </Space>
              ))}
            </Panel>
          </Space>
        );
      })}
    </>
  );
};

export default observer(Addons);
