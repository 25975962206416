import { ListModel } from 'models/list';
import { ProductItem } from 'models/product';
import { BaseResponse } from 'types/meta';
import { IRootStore } from 'types/rootStore';
import {
  GetProductListDocument,
  GetProductListQuery,
  GetProductListQueryVariables,
} from 'generated/graphql';
import { ValueModel } from 'models/value';
import { IReactionDisposer, reaction } from 'mobx';
import debounce from 'lodash.debounce';

const LIST_LIMIT = 20;

export class ProductListStore {
  readonly products: ListModel<ProductItem> = new ListModel<ProductItem>();
  private rootStore: IRootStore;
  readonly search: ValueModel = new ValueModel<string>('');
  readonly category: ValueModel<string | null>;
  private handleSearchChanging: IReactionDisposer;
  private handleCategoryChanging: IReactionDisposer;

  constructor(rootStore: IRootStore, category?: string) {
    this.rootStore = rootStore;

    this.category = new ValueModel<string | null>(category || null);

    this.handleSearchChanging = reaction(
      () => this.search.value,
      this.loadDebounced
    );
    this.handleCategoryChanging = reaction(
      () => this.category.value,
      this.loadDebounced
    );
  }

  load = async (initial = false): Promise<BaseResponse> => {
    if (this.products.loadingStage.isLoading) {
      return {
        isError: true,
      };
    }

    this.products.loadingStage.loading();

    const { data } = await this.rootStore.apolloClient.query<
      GetProductListQuery,
      GetProductListQueryVariables
    >({
      query: GetProductListDocument,
      variables: {
        limit: LIST_LIMIT,
        offset: initial ? 0 : this.products.length,
        filters: {
          category: this.category.value,
          search: this.search.value,
        },
      },
    });

    if (data.productList) {
      this.products.hasMore.change(!(Number(data.productList.count) < LIST_LIMIT));
      const keys: string[] = [];
      const entities: Record<string, ProductItem> = {};

      data.productList.products.forEach((product) => {
        const model = ProductItem.fromJson(product, this.rootStore.projectStore);

        keys.push(model.id);
        entities[model.id] = model;
      });

      this.products.addEntities({
        entities,
        keys,
        initial: initial || this.products.length === 0,
      });

      this.products.loadingStage.success();

      return {
        isError: false,
      };
    } else {
      this.products.loadingStage.error();

      return {
        isError: true,
      };
    }
  };

  private loadDebounced = debounce(() => this.load(true), 500);
}
